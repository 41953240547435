import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'
import store from '@/store'

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/about-us',
    name: 'aboutUs',
    component: () => import('../views/AboutUs.vue'),
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('../views/Service.vue'),
  },
  {
    path: '/contact-us',
    name: 'contactUs',
    component: () => import('../views/ContactUs.vue'),
  },
  {
    path: '/j',
    name: 'jump',
    component: () => import('../views/Jump.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Register.vue'),
  },
  {
    path: '/register-success',
    name: 'register-success',
    component: () => import('../views/RegisterSuccess.vue'),
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('../views/ForgotPassword.vue'),
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/Search.vue'),
    meta: {
      auth: true,
      keepAlive: true,
      savePos: true
    }
  },
  {
    path: '/detail/:sku',
    name: 'detail',
    component: () => import('../views/Detail.vue'),
    meta: {
      auth: true,
      keepAlive: true
    }
  },
  {
    path: '/user',
    name: 'user-layout',
    redirect: '/user/profile',
    component: () => import('../views/user/Layout.vue'),
    meta: {
      auth: true
    },
    children: [
      {
        path: 'profile',
        name: 'user-profile',
        meta: {
          auth: true
        },
        component: () => import('../views/user/Profile.vue'),
      },
      {
        path: 'inquiry',
        name: 'user-inquiry',
        meta: {
          auth: true
        },
        component: () => import('../views/user/Inquiry.vue'),
      },
      {
        path: 'favorite',
        name: 'user-favorite',
        meta: {
          auth: true
        },
        component: () => import('../views/user/Favorite.vue'),
      },
      {
        path: 'require',
        name: 'user-require',
        component: () => import('../views/user/Require.vue'),
        meta: {
          auth: true,
        }
      },
      {
        path: 'userManager',
        name: 'user-manager',
        meta: {
          auth: true
        },
        component: () => import('../views/admin/User.vue'),
      },
      {
        path: 'userGoodsManager',
        name: 'user-gooods-manager',
        meta: {
          auth: true
        },
        component: () => import('../views/admin/Goods.vue'),
      },
      {
        path: 'userCarManager',
        name: 'user-car-manager',
        meta: {
          auth: true
        },
        component: () => import('../views/admin/Car.vue'),
      },
      // {
      //   path: 'userGoodsUpdate/:sku',
      //   name: 'user-gooods-update',
      //   meta: {
      //     auth: true
      //   },
      //   component: () => import('../views/admin/GoodsUpdate.vue'),
      // },
      {
        path: 'userInquiry',
        name: 'user-inquiry-manager',
        meta: {
          auth: true
        },
        component: () => import('../views/admin/Inquiry.vue'),
      },
      {
        path: 'userRequire',
        name: 'user-require-manager',
        component: () => import('../views/admin/Require.vue'),
        meta: {
          auth: true,
        }
      },
      {
        path: 'priceParmas',
        name: 'price-params-manager',
        component: () => import('../views/admin/PriceParmas.vue'),
        meta: {
          auth: true,
        }
      },
      {
        path: 'userOrder',
        name: 'user-order',
        meta: {
          auth: true
        },
        component: () => import('../views/admin/Order.vue'),
      },
    ]
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/404.vue'),
    meta: {
      auth: true
    }
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 如果存在 savedPosition（例如通过浏览器的前进/后退按钮导航），则滚动到该位置
    if (savedPosition) {
      return savedPosition;
    } else {
      // 否则滚动到页面顶部
      return { x: 0, y: 0 };
    }
  }
})


router.beforeEach((to, from, next) => {

  let user = null
  const localUser = Cookies.get('CBEC_CJ_ACCOUNT')

  try {
    // 获取本地存储的 User
    if (localUser) {
      user = JSON.parse(localUser)
      store.commit('SET_USER', user)
    }
  } catch (error) {
    console.log(error)
  }

  // 路由是否需要权限
  if (to.meta.auth) {

    if (user) {
      // 用户已经登陆直接跳转
      next()
    } else {
      // 用户未登陆直接返回登录页
      next({
        name: 'login'
      })
    }
  } else {
    next()
  }


})

export default router
